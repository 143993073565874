import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import N5_1 from "../../Assets/Certification/N5 MJT テスト.png";
import N5_2 from "../../Assets/Certification/N5 Mazii テスト.png";
import N4_1 from "../../Assets/Certification/N4 MJT テスト.png"
import English from "../../Assets/Certification/Topnotch 3.png";
import SUPERTEAMVN from "../../Assets/Certification/Certificate of Completion in Programming Internship from SUPERTEAMVN.jpg";
import Particle from "../Particle";
import ProjectCards from "../Projects/ProjectCards";
function Certification() {
    return (
        <Container fluid className="about-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    My <strong className="purple">Certificate </strong>
                </h1>
                <p style={{ color: "white" }}>
                    Here are a my certificate.
                </p>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    <Col md="4" className="project-card">
                        <ProjectCards
                            imgPath={English}
                        />
                    </Col>
                    <Col md="4" className="project-card">
                        <ProjectCards
                            imgPath={N5_2}
                        />
                    </Col>
                    <Col md="4" className="project-card">
                        <ProjectCards
                            imgPath={SUPERTEAMVN}
                        />
                    </Col>
                    <Col md="4" className="project-card">
                        <ProjectCards
                            imgPath={N5_1}
                        />
                    </Col>
                    <Col md="4" className="project-card">
                        <ProjectCards
                            imgPath={N4_1}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Certification;
